/*
// jei reikia, galime perrasyti core komponentus, pvz.
init_tooltips = function(context) {
	$('[data-toggle="tooltip"]', context).tooltip({placement:'bottom'});
}
*/

$(function () {
    update_breadcrumb();

    $(window).resize(function() {
        update_breadcrumb();
    });


    // language change click
    $('.act-change-lang').on('click', function(e) {
        e.preventDefault();

        var lang_href = $(this).data('href');
        var lang_key = $(this).data('lang');

        $.ajax({
            url: '?display=content_types/pages/change_lang',
            data: { lang_key: lang_key, href: lang_href, params: window.location.search.substr(1) },
            method: 'post',
            dataType: 'json',
            success: function(json) {
                if (json.url) {
                    window.location.href = json.url;
                } else {
                    location.reload();
                }
            }
        });
    });
});